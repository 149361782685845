<template>
  <v-container fluid fill-height class="background">
    <v-layout>
      <v-row class="">
        <v-col 
          xs="12" 
          sm="12"
          md="12"
          lg="5"
          xl="4"
          class="d-flex mx-auto justify-center align-center"
        >
          <v-card
            class="pa-9 pb-5"
          >
            <v-img
                lazy-src="../assets/eme4.png"
                width="120px"
                src="../assets/eme4.png"
                class="mb-6 mx-auto"
            ></v-img>
            <v-form @submit.prevent="auth">
              <v-text-field
                v-model="user"
                :error-messages="userErrors"
                color="blue"
                label="Usuário"
                class="pa-0"
                filled
                required
              ></v-text-field>
              <v-text-field 
                v-model="password"
                :error-messages="passwordErrors"
                color="blue"
                label="Senha"
                :type="'password'"
                filled
                required
              ></v-text-field>
              <v-checkbox
                v-model="remember"
                label="Manter Logado"
                class="mt-0 pa-0"
                color="blue"
              ></v-checkbox>
              <v-btn
                :loading="loading"
                :disabled="loading"
                color="orange white--text"
                type="submit"
                elevation="2"
                class="mt-0"
                block
              >
                Entrar
              </v-btn>
            </v-form>
            <v-img 
              width="120"
              lazy-src="../assets/powered_by_datainfo_m.png"
              src="../assets/powered_by_datainfo_m.png"
              class="mx-auto mt-3"
            ></v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'
  import AuthService from '../services/AuthService'
  import { mapMutations } from "vuex";

  export default {
    name: 'LoginComponent',
    mixins: [validationMixin],
    validations: {
      user: { required },
      password: { required },
    },
    computed: {
      userErrors () {
        const errors = []
        if (!this.$v.user.$dirty) return errors
        !this.$v.user.required && errors.push('Informe o usuário!')
        return errors
      },
      passwordErrors () {
        const errors = []
        if (!this.$v.password.$dirty) return errors
        !this.$v.password.required && errors.push('Informe a senha!')
        return errors
      },
    },
    data: () => ({
      rules: {
        user: [
          value => !!value,
        ],
        password: [
          value => !!value,
        ],
      },
      loading: false,
      manterlogado: true,
      user: '',
      password: '',
      remember: false
    }),
    methods: {
      auth: async function() {
        console.log('auth')
        this.$v.$touch()

        if(this.user && this.password) {     
          this.loading = true
          console.log(this.remember)
          await AuthService.auth(this.user, this.password, this.remember)
          this.loading = false
        }
      },
      ...mapMutations("snackbar", ["setSnack"]),
      ...mapMutations('loadingoverlay', ['removeLoadingOverlay']),
    },
    mounted: function() {
      this.removeLoadingOverlay()
    }
  }
</script>
<style scoped>
  .background {
    background-image: url('../assets/chat_eme4_lia_fundo_azul.png');
    background-size: cover;
    background-position: center;
  } 

</style>